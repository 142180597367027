import React from 'react';
import { Box, Stack } from '@mui/material';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { FormInput } from '../forms';
import { MainButton } from '../buttons';
import { REGISTER_BY_EMAIL, REGISTER_BY_PHONE } from '../../redux/slice/auth/auth.actions';
import useRoles from '../../react-query/hooks/useRoles';
import { UseMutateFunction } from '@tanstack/react-query';
import { ApiResponse, Props, RequestError } from '../../react-query/hooks/useRegister';

type ACTION = 'BY EMAIL' | 'BY PHONE';

interface ISignup {
  action: ACTION;
  registerFrom: string | any;
  registerAs: string | any;
  mutate: UseMutateFunction<ApiResponse, RequestError, Props, unknown>;
  isLoading?: boolean;
}

const SignupForm = ({ action, registerAs, registerFrom, mutate, isLoading = false }: ISignup) => {
  const { id } = useRoles(registerAs);

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required('First name is required'),
    last_name: Yup.string().required('First name is required'),
    email: action === 'BY EMAIL' ? Yup.string().email('Invalid email').required('Email is required') : Yup.string(),
    password: Yup.string()
      .required('Password is required')
      .min(8, 'Password must be at least 8 characters')
      .matches(
        // eslint-disable-next-line no-useless-escape
        /^(?=.*[!@#$%^&*()_+}{:;'?/.,\[\]\-])(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z]).{8,}$/,
        'Password must contain at least one special character, one number, one uppercase letter, and one lowercase letter'
      )
      .label('Password'),
    phone_number:
      action === 'BY PHONE'
        ? Yup.string()
            .matches(/^[0-9]{11}$/, 'Phone number is invalid')
            .required('Phone number is required')
        : Yup.string()
  });

  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      phone_number: ''
    },
    validationSchema,
    onSubmit: async (val) => {
      action === 'BY EMAIL' &&
        id &&
        mutate({
          firstName: val.first_name,
          lastName: val.last_name,
          email: val.email,
          password: val.password,
          phone: '',
          roleId: id
        });
      // handle registration by phone number
      action === 'BY PHONE' &&
        id &&
        mutate({
          firstName: val.first_name,
          lastName: val.last_name,
          email: '',
          phone: val.phone_number,
          password: val.password,
          roleId: id
        });
    }
  });

  return (
    <FormikProvider value={formik}>
      <Form noValidate onSubmit={formik.handleSubmit}>
        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} mb={2}>
          <Box flex={1}>
            <FormInput name={'first_name'} label={'first name'} placeholder={'First name'} hasMessage fullWidth />
          </Box>
          <Box flex={1} ml={1}>
            <FormInput name={'last_name'} label={'last name'} hasMessage fullWidth placeholder={'Last name'} />
          </Box>
        </Stack>
        {action === 'BY EMAIL' && (
          <FormInput
            name={'email'}
            label={'email'}
            hasMessage
            fullWidth
            placeholder={'you@user.com'}
            mb={3}
            required={action === 'BY EMAIL'}
          />
        )}
        {action === 'BY PHONE' && (
          <FormInput
            name={'phone_number'}
            label={'phone number'}
            hasMessage
            fullWidth
            placeholder={'08012345678'}
            mb={3}
            pattern='[0-9]*'
            type='tel'
          />
        )}

        <FormInput
          name={'password'}
          isPassword
          label={'password'}
          fullWidth
          hasMessage
          mb={3}
          placeholder={'Enter your password'}
        />
        <MainButton
          label={'Sign up'}
          type={'submit'}
          color={'primary'}
          fullWidth
          padding={'10px 18px'}
          loading={isLoading}
        />
      </Form>
    </FormikProvider>
  );
};

export default SignupForm;
