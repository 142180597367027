import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: 'https://staging.gogeeper.co.uk/api/v1'
});

// Function to set the token in the request headers
const setAuthToken = (token: string) => {
  axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

class APIClient<T, K = undefined> {
  endpoint: string;

  constructor(endpoint: string) {
    this.endpoint = endpoint;
  }
  //  get all data....
  getAll = () => {
    return axiosInstance.get<T[]>(this.endpoint).then((response) => response.data);
  };
  //   create single data....
  post = (data: K) => {
    return axiosInstance.post<T>(this.endpoint, data).then((response) => response.data);
  };
  //   create single data....
  put = (data: K) => {
    return axiosInstance.put<T>(this.endpoint, data).then((response) => response.data);
  };

  //   get single item
  get = () => {
    return axiosInstance.get<T>(this.endpoint).then((response) => response.data);
  };
}

export { APIClient, setAuthToken };
// new APIClient<Todos>('/post')
