import { nanoid } from 'nanoid';
import React from 'react';
import { useParams } from 'react-router-dom';
import AuthScreensLayout from '../layouts/AppLayout';
import { AuthCardView, FooterLink, Headline, SignupForm, TabComponent } from '../components/auth';
import useRegister from '../react-query/hooks/useRegister';
import LoadingLayout from '../layouts/loadingLayout/LoadingLayout';
import ErrorNotification from '../components/Notification/ErrorNotification';
import SuccessNotification from '../components/Notification/SuccessNotification';

const tabs = [
  {
    tab: 'email',
    value: '1'
  },
  {
    tab: 'phone number',
    value: '2'
  }
];

const RegistrationScreen = () => {
  const { role, fromUml } = useParams();
  // handle the api request using useMutation
  const { mutate, isLoading, error, data } = useRegister();
  return (
    <LoadingLayout isLoading={isLoading}>
      <AuthScreensLayout>
        <Headline title={'Welcome! Create your account'} subtitle={'Enter the details below correctly'} />
        {error && (
          <ErrorNotification>
            <p>{error.response.data.message || error.message}</p>
          </ErrorNotification>
        )}
        <AuthCardView>
          <TabComponent
            tabs={tabs}
            firstChild={
              <SignupForm
                action={'BY EMAIL'}
                registerFrom={fromUml}
                registerAs={role}
                mutate={mutate}
                isLoading={isLoading}
              />
            }
            secondChild={
              <SignupForm
                action={'BY PHONE'}
                registerFrom={fromUml}
                registerAs={role}
                mutate={mutate}
                isLoading={isLoading}
              />
            }
          />
        </AuthCardView>
        <FooterLink
          to={`/oauth/authorize/login/fromUmlAssign/${fromUml}/initiator_id/${nanoid(35)}`}
          linkText={'Log in'}
          grayText={'Have an account?'}
        />
      </AuthScreensLayout>
    </LoadingLayout>
  );
};

export default RegistrationScreen;
